import react, {useState} from "react";
import styled from "styled-components";
import background from "../assets/background8.jpg";
import LandingFooter from "../components/LandingFooter";
import { db } from "../services/Firebase";

const Container = styled.div`
  // height: 100vh;
  padding-top: 60px;
  width: 100vw;
  // background-color: #fff;

  background-image: url(${background});
  background-size: cover;
  background-position: center;
  // background-opacity: 0.5;
  background-repeat: no-repeat;
  background-attachment: fixed;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    flex-direction: column;
    background-attachment: scroll;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-top: 20px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    width: 95%;
    margin: auto;
  }
`;

const Title = styled.h1`
  font-size: 60px;
  width: 80%;
  font-weight: 400;
  color: #000;
  // line-height: 0.1;

  @media (max-width: 768px) {
    font-size: 40px;
    // width: 80%;
    margin-top: 85px;
    // margin-left: 10%;
  }
`;

const Para = styled.p`
  font-size: 20px;
  width: 80%;
  font-weight: 400;
  color: #000;
  // line-height: 0.1;

  @media (max-width: 768px) {
    font-size: 20px;
    // width: 80%;
    // margin-top: 85px;
    // margin-left: 10%;
  }
`;

const EmailInput = styled.input`
  width: 100%;
  height: 50px;
  border: 2px solid #000;
  border-radius: 10px;
  background-color: transparent;
  // padding-left: 25px;
  padding: 17px;
  font-size: 2em;
  margin-top: 20px;

  @media (max-width: 768px) {
    // width: 80%;
    margin: auto;
    margin-top: 20px;
    // margin-left: 10%;
  }
`;

const Button = styled.button`
  width: 100%;
  // height: 50px;
  padding: 20px;
  border: none;
  border-radius: 0px 20px 0px 0px;
  background-color: #000;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-top: 20px;
  border: 2px solid #000;

  @media (max-width: 768px) {
    // width: 80%;
    margin: auto;
    margin-top: 20px;
    margin-left: 5%;
    margin-bottom: 80px;
  }

  :hover {
    cursor: pointer;
    background-color: transparent;
    color: #000;
    border: 2px solid #000;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin-top: 20px;
  margin-bottom: 150px;

  @media (max-width: 768px) {
    width: 80%;
    margin: auto;
  }
`;

const AlphaLaunch = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    // check if email is valid: must contain @ and . and not be empty
    if (!email.includes("@") || !email.includes(".") || email === "") {
      alert("Please enter a valid email address");
      return;
    }

    db.collection("alphaEmails").add({
      email: email,
    });

    setEmail("");

    alert("Thank you for joining our alpha program!");
  };

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Container>
      <Wrapper>
        <Title>
          Signup for our mailing list to stay upto date with the release?
        </Title>

        <Form onSubmit={handleSubmit}>
          <EmailInput
            type="email"
            placeholder="Enter Email"
            onChange={handleChange}
          />
          <Button>Apply</Button>
        </Form>
      </Wrapper>
      <LandingFooter />
    </Container>
  );
};

export default AlphaLaunch;
